<section class="page-title-area">
    <div class="container">
        <div class="page-title-content wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
            <img src="https://file-storage.sfo3.cdn.digitaloceanspaces.com/team+.png" alt="Team+ Logo">
            <h1 class="pt-3">Welcome to Team+ on Register Play<br>Empower Your Team. Elevate Your Game.</h1>
            <h3 class="pt-5">Discover Team+</h3>
            <p>Team+ is the latest innovation from Register Play, designed to revolutionize the way sports teams operate. With Team+, coaches and players come together on a singular platform to manage activities, track availability, and foster seamless communication. It's more than a tool; it's your team's new digital headquarters.</p>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>
<section class="features-area bg-f9f9f9 pb-70">
    <div class="container position-relative z-index-3 my-5">
        <!-- Why Team+? -->
        <div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
            <div class="col-lg-12 appear-animation animated fadeInRightShorter" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
                <h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Why Team+?</h4>
                <p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Team+ is more than just a feature; it's a game-changer for sports teams. By integrating scheduling, communication, and participation tracking into one platform, we're not just simplifying team management—we're enhancing the sports experience for coaches, players, and parents alike.</p>
            </div>
        </div>
        <hr>
        <!-- promo video -->
        <div style="position: relative; padding-top: 56.25%;">
            <iframe src="https://customer-pdc4yijcmuyr5pl1.cloudflarestream.com/7308489c66dd4d1c4a88639bf47c8707/iframe?poster=https%3A%2F%2Fcustomer-pdc4yijcmuyr5pl1.cloudflarestream.com%2F7308489c66dd4d1c4a88639bf47c8707%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D12s%26height%3D600" loading="lazy" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
        </div>
        <hr>
        <!-- Unified Activity Management -->
        <div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
            <div class="col-lg-12 appear-animation animated fadeInRightShorter" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
                <h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Unified Activity Management</h4>
                <p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Navigate your team's schedule with ease using the dynamic Activity tab. Switch between a comprehensive calendar view and a detailed list view to stay on top of practices, games, and meetings. Each activity is a click away from revealing its full details and associated tasks.</p>
            </div>
        </div>
        <!-- RSVP Simplified -->
        <div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
            <div class="col-lg-12 appear-animation animated fadeInRightShorter" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
                <h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">RSVP Simplified</h4>
                <p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Say goodbye to endless email threads. Coaches and parents can now RSVP for activities directly within Team+. With a clear view of who will be attending, managing participation has never been easier.</p>
            </div>
        </div>
        <!-- Streamlined Communication -->
        <div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
            <div class="col-lg-12 appear-animation animated fadeInRightShorter" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
                <h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Streamlined Communication</h4>
                <p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Enhance your team's connection with our comprehensive Communication tab, allowing for direct messages and announcements to keep everyone on the same page.</p>
            </div>
        </div>
        <!-- Volunteer Opportunities -->
        <div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
            <div class="col-lg-12 appear-animation animated fadeInRightShorter" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
                <h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Volunteer Opportunities</h4>
                <p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Engage with your team by volunteering for open assignments. Whether it's helping out at an event or taking on a specific role, Team+ makes it easy to contribute.</p>
            </div>
        </div>
        <!-- Scheduling Freedom -->
        <div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
            <div class="col-lg-12 appear-animation animated fadeInRightShorter" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
                <h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Scheduling Freedom</h4>
                <p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Authorized users can effortlessly create activities, ensuring the entire team is informed and aligned. From local practices to inter-league games, organizing your team's calendar is just a few clicks away.</p>
            </div>
        </div>
        <!-- Availability -->
        <div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
            <div class="col-lg-12 appear-animation animated fadeInRightShorter" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
                <h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Availability at a Glance</h4>
                <p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">With the Availability tab, team members can mark their availability, allowing coaches to tailor plans and strategies. A weekly overview ensures no one misses out, enhancing team coordination.</p>
            </div>
        </div>
        <hr>
        <!-- Try Team+ Free -->
        <div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
            <div class="col-lg-12 appear-animation animated fadeInRightShorter" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
                <h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Try Team+ Free for 3 Weeks</h4>
                <p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mt-5 mb-2 text-justified"> Experience <b>Team+</b> with our 3-week free trial — no credit card required. Discover the difference it can make in streamlining your team's management and communication efforts.<br><br> To activate your trial, visit your "Team Page," select the <b>Team+</b> tab, and follow the instructions.</p>
            </div>
        </div>
        <!-- Try Team+ Free -->
        <div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
            <div class="col-lg-12 appear-animation animated fadeInRightShorter" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
                <h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Keep Team+ Beyond the Trial</h4>
                <p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mt-5 mb-2 text-justified"> Loved your <b>Team+</b> experience? Continue transforming your team's coordination and engagement by keeping <b>Team+</b>.<br><br> To keep <b>Team+</b> for your team, visit your "Team Page," select the <b>Team+</b> tab, and follow the instructions.<br><br> Our pricing is only based on the time left in the season, ensuring you receive value throughout, regardless of your team size. </p>
            </div>
        </div>
    </div>
</section>
