<div class="p-2 thank-you" mat-dialog-close>
    <!-- <img [src]="imageURL" alt="thank-you"> -->
    <div class="p-2">

        <!-- <h5 class="text-center">Happy Thanksgiving from<br>Register Play!</h5>
            <p>As we gather around to celebrate Thanksgiving, we want to extend our warmest wishes to you and your loved ones. We are truly thankful for your continued support and the opportunity to be a part of your journey.</p>
            <p>May your Thanksgiving be filled with happiness, good health, and memorable plays!</p>

            <p>Register Play Team</p> -->


        <!-- Trade Show -->
        <div class="announcement">
            <p class="text-center" style="margin: 12px 0 10px 0">Join Us at the </p>
            <p class="text-center caption">USA Softball Annual Meeting & Trade Show!</p>
            <div style="display: flex;align-items: flex-start;gap: 24px">
                <img src="https://file-storage.sfo3.cdn.digitaloceanspaces.com/conventions/OKCity.webp" alt="USA Softball Logo">
                <div>
                    <p style="text-align: justify">We are excited to announce that Register Play will be attending the <b>USA Softball Annual Meeting</b> at the <strong>Oklahoma City Convention Center</strong>!<br>We invite you to connect with us in person for an exclusive 1-on-1 meeting where you can learn more about our platform and how it can elevate your tournaments and teams.</p>
                    <p><b>Available for meetings:</b> Sunday afternoon, all day Monday, and Tuesday until noon. Secure your spot and schedule a meeting here.</p>
                    <p>We look forward to seeing you there!</p>
                </div>
            </div>
            <p class="text-center">
                <a href="https://calendly.com/registerplay/usa-softball-annual-meeting" target="_blank" class="cta-button">Schedule a Meeting</a>
            </p>
        </div>
    </div>
</div>
