import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageOneComponent } from './components/pages/homepage-one/homepage-one.component';
import { PricingPageComponent } from './components/pages/pricing-page/pricing-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { LeagueSolutionsComponent } from './components/pages/solutions/leagues/leagues.component';
import { StateOfficesComponent } from './components/pages/solutions/stateOffices/stateOffices.component';
import { LocalAssociationsComponent } from './components/pages/solutions/localAssociations/localAssociations.component';
import { TeamsComponent } from './components/pages/solutions/teams/teams.component';
import { PlayersComponent } from './components/pages/solutions/players/players.component';
import { SportsHomeComponent } from './components/pages/sports/home/sportsHome.component';
import { SportDetailComponent } from './components/pages/sports/detail/sportDetail.component';
import { ComparisonPageComponent } from './components/pages/comparison/comparison.component';
import { BlogListComponent } from './components/pages/blog-list/blog-list.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { IndvEventSolutionsComponent } from './components/pages/solutions/indv-events/indv-events.component';
import { TeamPlusComponent } from './components/pages/solutions/teams/teamsPlus/TeamsPlus.component';

const routes: Routes = [
    {path: '', component: HomepageOneComponent},
	{ path: 'solutions/leagues', component: LeagueSolutionsComponent },
	{ path: 'solutions/individual-events', component: IndvEventSolutionsComponent },
	{ path: 'solutions/state-offices', component: StateOfficesComponent },
	{ path: 'solutions/sport-organizations', component: LocalAssociationsComponent },
	{ path: 'solutions/clubs', component: LocalAssociationsComponent },
	{ path: 'solutions/teams', component: TeamsComponent },
	{ path: 'solutions/teamPlus', component: TeamPlusComponent },
	{ path: 'solutions/players', component: PlayersComponent },

	{ path: 'sports', component: SportsHomeComponent },
	{ path: 'sport/:code', component: SportDetailComponent },
	{ path: 'comparison', component: ComparisonPageComponent },
	{ path: 'contact', component: ContactPageComponent },

	{ path: 'blog', component: BlogListComponent },
	{ path: 'blog/:slug', component: BlogDetailsComponent },


    {path: 'pricing', component: PricingPageComponent},
    // Here add new pages component

    {path: '**', component: ErrorPageComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
