<section class="page-title-area">
    <div class="container">
        <div class="page-title-content wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
    		<img src="assets/img/icons/local-association.svg" alt="image">
            <h1 class="pt-3">Local Associations / Clubs:<br>Empowering Community Sports Organizations</h1>
			<p>REGISTER PLAY caters to the specific needs of local sports associations and clubs, providing a dedicated platform to streamline operations, enhance member experiences, and promote community engagement. Whether you're managing a local sports league, community club, or grassroots organization, our platform offers a range of features designed to support your unique requirements.</p>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="services-area bg-f9f9f9 pb-70">
	<div class="container position-relative z-index-3 my-5">
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation animated fadeInRightShorter appear-animation-visible" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Membership and Registration</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Effortlessly handle membership management for your association or club while streamlining the registration process for athletes. Keep track of coaches' compliance effortlessly. With <app-RP/>, you can securely collect membership dues and maintain a well-organized member database. Simplify the registration experience for your community, ensuring a seamless and hassle-free participation process. Access your team schedules conveniently and have them readily available at your fingertips.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Event and Tryout Management</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Organize and run local events, tryouts, clinics seamlessly with our event management features. From scheduling games to managing team entries and generating results, <app-RP/> simplifies event logistics, saving you time and effort. Promote community sports and provide opportunities for athletes of all ages and skill levels to participate and compete.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Data and Reporting</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Access comprehensive data and reporting capabilities to gain valuable insights into activities within your state. Our state office platform provides real-time analytics, allowing you to track participation numbers, monitor performance trends, and have access wide variety of reports. Leverage data-driven decision-making to drive growth, allocate resources effectively, and enhance sports development initiatives in your state.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Communication and Engagement</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Effective communication is vital for success of an association. <app-RP/> provides communication tools to connect with members, teams, coaches, and parents. Share important updates, event announcements, and league news easily through our messaging system. Foster engagement within your community, promoting a sense of belonging and creating a vibrant sports culture.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Coach/Volunteer Management</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Efficiently manage your coaches and volunteers who contribute their time and skills to your association or club. <app-RP/> allows you to streamline registration, assignments, and communicate with them. Simplify the process of assigning roles and responsibilities, ensuring a smooth operation and a strong community support network.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Customization and Branding</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Customize your association or club website within the <app-RP/> platform to reflect your unique identity. Add your logo, club colors, and personalized branding to create a cohesive visual experience. Strengthen your organization's presence and promote a strong sense of community among your members.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mt-5 mb-2 text-justified">Join the community of local associations and clubs that trust <app-RP/> to simplify their operations and enhance the sports experience for their communities. Experience the power of streamlined administration, improved communication, and enhanced community engagement. Get started today and revolutionize sports at the local level with <app-RP/>.</p>
			</div>
		</div>

		<section class="feedback-area ptb-100 mt-5 wow animate__animated animate__fadeInLeftBig" data-wow-delay="00ms" data-wow-duration="1000ms">
			<app-feedback page="club"></app-feedback>
		</section>
    </div>
</section>
