<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/logo-white.png" alt="logo">
                    </a>
                    <p>Join Register Play, your ultimate tournament management solution. Simplify registration, optimize scheduling, and enhance communication. Elevate your sports organization.</p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/RegisterPlaySoftware" class="d-block" target="_blank"><img src="assets/img/icons/fb.png"></a></li>
                        <li><a href="https://twitter.com/RegisterPlayInc" class="d-block" target="_blank"><img src="assets/img/icons/x.png"></a></li>
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li> -->
                    </ul>
                    <!-- <div style="display: flex;">
                        <a target="_blank" href="https://www.capterra.com/reviews/343415/RegisterPlay?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"> <img border="0" src="https://assets.capterra.com/badge/03a6e162b1178020f28ab042ea75ee9f.svg?v=2290412&p=343415" style="max-width: 150px" /></a>
                        <a target="_blank" href="https://www.getapp.com/recreation-wellness-software/a/registerplay/reviews/"><img border="0" src="https://www.getapp.com/ext/reviews_widget/v1/dark/registerplay-application" style="max-width: 150px" /></a>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
                <div class="single-footer-widget pl-5" style="padding-left: 40px;">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/sports">Sports</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li><a href="https://calendly.com/registerplay/contact-us" target="_blank">Request Demo</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms">
                <div class="single-footer-widget pl-2">
                    <h3>Solutions</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/solutions/individual-events">Individual Events</a></li>
                        <li><a routerLink="/solutions/leagues">Leagues</a></li>
                        <li><a routerLink="/solutions/state-offices">State Offices</a></li>
                        <li><a routerLink="/solutions/sport-organizations">Sport Organizations</a></li>
                        <li><a routerLink="/solutions/teams">Teams</a></li>
                        <li><a routerLink="/solutions/players">Players</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms">
                <div class="single-footer-widget">
                    <h3>Address</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>1309 Coffeen Ave ST 1200<br>Sheridan, WY 82801, USA</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:7755777529">(775) 57-RPLAY</a></li>
                        <li><i class='bx bx-phone-call'></i>(775) 577-7529</li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info@registerplay.com">info&#64;registerplay.com</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p *ngFor="let Text of copyrightText;"><i class='bx bx-copyright'></i> {{Text.text}} <a target="_blank" href="{{Text.link}}">{{Text.linkText}}</a></p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="terms-of-service">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
    <div class="shape16"><img src="assets/img/shape/shape16.png" alt="image"></div>
</footer>

<!-- End Footer Area -->

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>
