import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent {
    imageURL: string = '/assets/img/thanksgiving.webp';
    isMobile: boolean = false;
    // constructor(@Inject(MAT_DIALOG_DATA) public data: any){
    //     if (data && data.isMobile){
    //         this.imageURL = '/assets/img/thank-you-mobile.webp';
    //         this.isMobile = true;
    //     }
    // }
}
