import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
