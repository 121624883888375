import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-sportDetail',
	templateUrl: './sportDetail.component.html',
	styleUrls: ['./sportDetail.component.scss']
})
export class SportDetailComponent implements OnInit {

	sports = [
		{ "name": "Softball", "code": "softball", color: 'red' },
		{ "name": "Baseball", "code": "baseball", color: 'blue' },
		{ "name": "Basketball", "code": "basketball", color: 'orange' },
		{ "name": "Football", "code": "football", color: 'rred' },
		{ "name": "Curling", "code": "curling", color: 'yellow' },
		{ "name": "Hockey", "code": "hockey", color: 'green' }
	];
	selectedSport: any;
	constructor(private route: ActivatedRoute) { }

	ngOnInit(): void {
		// subscribe to the navigation end event
		this.route.params.subscribe(params => {
			// get the code from the url
			let code = params['code'];
			// find the sport in the array of sports
			this.selectedSport = this.sports.find(sport => sport.code === code);
		});
	}

}
