<section class="page-title-area">
    <div class="container">
        <div class="page-title-content wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
			<span class="sub-title" style="color: #fff">Ready to Get Started?</span>
            <h1>Contact Us</h1>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="contact-info-area pt-100 pb-70">
    <div class="container">
		<div class="section-title wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<h2>Get in Touch</h2>
			<p>Reach out to us via email, or a call.</p>
			<p>Let's get started on your journey!</p>
		</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
                <div class="contact-info-box">
                    <div class="back-icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Our Address</h3>
                    <p>1309 Coffeen Ave<br>ST 1200<br>Sheridan, WY 82801, USA</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms">
                <div class="contact-info-box">
                    <div class="back-icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Contact</h3>
                    <p>Phone: <a href="tel:7755777529">(775) 57-RPLAY</a></p>
                    <p>Phone: (775) 577-7529</p>
                    <p>E-mail: <a href="mailto:info@registerplay.com">info&#64;registerplay.com</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
