<div class="it-partner-area pb-70">
    <div class="container">
        <div class="row align-items-center">
			<div class="col-lg-2 col-6 col-sm-4 col-md-4" *ngFor="let site of sites">
				<div class="it-partner-item wow animate__animated animate__fadeInDownBig" data-wow-delay="00ms" data-wow-duration="1000ms">
					<a [href]="site.url" target="_blank"><img [src]="site.img"></a>
				</div>
			</div>
        </div>
    </div>
</div>