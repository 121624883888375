import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.scss']
})
export class WhatWeDoComponent implements OnInit {

	topics = [
		{
			"title": "Why Choose Register Play?",
			"text": "Choose Register Play for seamless tournament management, combining cutting-edge technology, streamlined efficiency, and exceptional support, all in one comprehensive platform. Experience the difference today and unlock the full potential of your tournaments."
		},
		{
			"title": "How Does Register Play Simplify Tournament Management?",
			"text": "Register Play simplifies tournament management by providing an all-in-one platform that seamlessly integrates registration, scheduling, communication, and more. With its intuitive interface and advanced features, directors can streamline administrative tasks, save time, and ensure a smooth and efficient tournament experience for all participants."
		},
		{
			"title": "What Are the Key Features and Benefits of Register Play?",
			"text": "Register Play offers a range of key features and benefits to enhance tournament management. Enjoy the convenience of seamless registration, advanced scheduling options, streamlined communication, real-time updates, and a user-friendly interface. You can save time, reduce administrative burdens, improve participant engagement, and deliver exceptional tournaments that leave a lasting impression."
		},
		{
			"title": "Can Register Play Accommodate Different Tournament Formats and Rules?",
			"text": "Register Play is designed to accommodate a wide range of tournament formats and rules. Whether you're organizing pool plays, elimination brackets, consolation rounds, Register Play offers the flexibility to adapt to your specific needs. With customizable scheduling options, additional information collection on entry, individual events, clinic, showcase camps, etc you can ensure that Register Play aligns perfectly with your unique requirements, allowing you to create and manage tournaments with ease and precision."
		},
		{
			"title": "How Secure is the Data on Register Play?",
			"text": "At Register Play, we prioritize the security and privacy of your data. We implement robust security measures to safeguard your information, ensuring that it is protected from unauthorized access, loss, or misuse. Our platform adheres to industry-standard encryption protocols, and we strictly follow best practices in data security. You can trust that your data on Register Play is handled with the utmost care and confidentiality, allowing you to focus on managing your tournaments with peace of mind."
		}
	]

  constructor() { }

  ngOnInit(): void {
  }

}
