<div class="container">
    <div class="row align-items-center" *ngIf="page == 'league'">
        <div class="col-lg-6 col-md-12">
            <div class="feedback-image">
                <img src="https://mnsoftball.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/usasb-mn-385573586.png&w=210&h=210&fit=contain" alt="image">
                <img src="https://myas.registerplay.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/myas-410125171.jpg&w=80&h=80&fit=contain" alt="image">
                <img src="https://mnsoftball.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/usasb-mn-162459912.png&w=80&h=80&fit=contain" alt="image">
                <img src="https://crowriverathletics.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/crowriver-421022643.png&w=80&h=80&fit=contain" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="feedback-content">
                <h2>What Our Clients Are Saying?</h2>
                <div class="feedback-slides owl-carousel owl-theme">
                    <div class="single-feedback-item">
						<blockquote class="ps-5-5 mb-0">
							<p><app-RP/> software saved me literally hundreds hours of scheduling.</p>
							<p>Scoring was easier for the coaches. Updating scoring errors or field conflicts was easier through the game change request feature they added for us.</p>
							<p>Our league, since it has always been done manually, we rescheduled each week based on the team's win/loss record. They created a program to easily schedule teams by win/loss, make sure no duplicates were scheduled, etc.</p>
							<p>Being that the leagues were huge, there were some manipulations needed since we used on average 36 sites each weekend. But the process was to simply drag and drop games around.</p>
						</blockquote>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <div class="title">
                                    <h3>Tom Bye</h3>
                                    <span>Tri County Softball</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row align-items-center" *ngIf="page == 'indv-event'">
        <div class="col-lg-6 col-md-12">
            <div class="feedback-image">
                <img src="https://demo.registerplay.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/usasb-mn-95588874.jpg&w=210&h=210&fit=contain" alt="USA Softball Minnesota Selects">
                <img src="https://demo.registerplay.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/usasb-al-540413336.jpg&w=80&h=80&fit=contain" alt="image">
                <img src="https://demo.registerplay.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/myas-640283057.png&w=80&h=80&fit=contain" alt="image">
                <img src="https://demo.registerplay.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/myas-613891723.png&w=80&h=80&fit=contain" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="feedback-content">
                <h2>What Our Clients Are Saying?</h2>
                <div class="feedback-slides owl-carousel owl-theme">
                    <div class="single-feedback-item">
						<blockquote class="ps-5-5 mb-0">
							<p>Working with REGISTER PLAY has been a game-changer for us! It's like they've taken our event management to the next level. We get a neat Excel file with all the player details lined up, so ordering jerseys is now a snap. They've woven volunteer coordination right into the nomination process – pure genius!</p>
							<p>Select Event's popularity is soaring higher than we ever dreamed; we went from 530 participants in 2021 to a whopping 902 in 2023!</p>
							<p>the best part? No more running after payments – REGISTERPLAY handles it all automatically. It's so user-friendly, we hit the ground running with zero stress.</p>
							<p>And the cherry on top? Those player stats on our event page – it's got everyone talking and competing, and that leaderboard is everyone's new obsession. Couldn't be happier!</p>
						</blockquote>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <div class="title">
                                    <h3>Tom Bye</h3>
                                    <span>Minnesota Softball</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

	<div class="row align-items-center" *ngIf="page == 'stateOffice'">
        <div class="col-lg-6 col-md-12">
            <div class="feedback-image">
                <img src="https://mnsoftball.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/usasb-mn-387679925.png&w=210&h=210&fit=contain" alt="image">
                <img src="https://ar.registerplay.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/usasb-ar-414866292.png&w=80&h=80&fit=contain" alt="image">
                <img src="https://myas.registerplay.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/myas-667584983.png&w=80&h=80&fit=contain" alt="image">
                <img src="https://ar.registerplay.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/myas-408198361.png&w=80&h=80&fit=contain" alt="image">

            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="feedback-content">
                <h2>What Our Clients Are Saying?</h2>
                <div class="feedback-slides owl-carousel owl-theme">
                    <div class="single-feedback-item">
						<blockquote class="ps-5-5 mb-0">
							<p>Providing superior customer service at Minnesota Softball for our players, teams, officials, and tournaments is a priority. We have used other providers. We have been developing our own software for ten years. It wasn't easy to stop development and put our business in someone else's hands. Until we met <app-RP/>.</p>
							<p><app-RP/> allows our customers to do all softball tasks on a singular website. Their customer service is nearly instantaneous. They roll out new features and updates frequently. There is not a company that delivers better amateur sports management tools than <app-RP/>, period.</p>
						</blockquote>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <div class="title">
                                    <h3>Dan Pfeffer</h3>
                                    <span>Minnesota Commissioner for USA Softball</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

	<div class="row align-items-center" *ngIf="page == 'club'">
        <div class="col-lg-6 col-md-12">
            <div class="feedback-image">
                <img src="https://mnsoftball.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/usasb-mn-354650698.jpg&h=210&w=210&fit=contain" alt="image">
                <img src="https://mnsoftball.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/myas-373230530.png&w=80&h=80&fit=contain" alt="image">
                <img src="https://dcfastpitch.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/usasb-mn-175799818.png&w=80&h=80&fit=contain" alt="image">
                <img src="https://ar.registerplay.com/img/?url=picture.nyc3.cdn.digitaloceanspaces.com/myas-384866705.png&w=80&h=80&fit=contain" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="feedback-content">
                <h2>What Our Clients Are Saying?</h2>
                <div class="feedback-slides owl-carousel owl-theme">
                    <div class="single-feedback-item">
						<blockquote class="ps-5-5 mb-0">
							<p>I cannot recommend <app-RP/> highly enough. This platform has completely transformed the way we manage our club and interact with our members. The user-friendly interface and comprehensive features have made a significant impact on our operations.</p>
							<p>Communication with our members has never been easier. <app-RP/>'s messaging system allows us to quickly reach out to our members, providing them with important updates, league announcements, and other relevant information. This has significantly improved engagement and fostered a sense of community within our club.</p>
							<p>Overall, <app-RP/> has revolutionized the way we operate our club. It has enhanced our efficiency, improved our member experiences, and elevated the professionalism of our organization. We are grateful for the impact <app-RP/> has had on our club's success, and we highly recommend it to any sports club looking to streamline their operations and enhance member engagement.</p>
						</blockquote>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <div class="title">
                                    <h3>Aaron Mevissen</h3>
                                    <span>Biglake Fastpitch</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
