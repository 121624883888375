import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
// import { ApiService } from '../../shared/services/api.services';

@Component( {
	selector: 'app-contact-page',
	templateUrl: './contact-page.component.html',
	styleUrls: [ './contact-page.component.scss' ],
} )
export class ContactPageComponent implements OnInit {
	// contactForm: FormGroup;
	// submitted = false;
	constructor(
		// private fb: FormBuilder,
		// private api: ApiService,
	) { }

	ngOnInit() {
		// this.contactForm = this.fb.group( {
		// 	name: [ '', [Validators.required] ],
		// 	email: [ '', [ Validators.required, Validators.pattern( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ) ] ],
		// 	phone: [ '', ],
		// 	message: [ '' ]
		// } );
	}

	// get f() { return this.contactForm.controls; }

	// onSubmit() {
	// 	this.submitted = true;
	// 	console.log( this.contactForm.value );
	// 	this.api.post("Comm/sendEmail", this.contactForm.value).subscribe((res: any) => {
	// 		console.log(res);
	// 	});
	// }

}
