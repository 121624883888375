import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-partner',
    templateUrl: './partner.component.html',
    styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

	sites = [
		{
			"url": "https://mnsoftball.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-mn/directorLogo.png"
		},
		{
			"url": "https://myas.registerplay.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/myas/directorLogo.png"
		},
		{
			"url": "https://ntxusasoftball.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-dfw/directorLogo.png"
		},
		{
			"url": "https://usasoftballofvirginia.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-va/directorLogo.png"
		},
		{
			"url": "https://arasa.org",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-ar/directorLogo.png"
		},
        {
            "url": "https://usasoftballallamericangames.com",
            "img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-aag/directorLogo.png"
        },
		{
			"url": "https://mdsoftball.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-md/directorLogo.png"
		},
        {
			"url": "https://norcal.registerplay.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-norcal/directorLogo.png"
		},
		{
			"url": "https://usasoftballofalabama.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-al/directorLogo.png"
		},
		{
			"url": "https://usasoftballflorida.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-fl/directorLogo.png"
		},
		{
			"url": "https://id.registerplay.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-id/directorLogo.png"
		},
		{
			"url": "https://usasoftballofla.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-la/directorLogo.png"
		},
		{
			"url": "https://usasoftballofms.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-ms/directorLogo.png"
		},
		{
			"url": "https://usasoftballnc.org",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-nc/directorLogo.png"
		},
        {
			"url": "https://wa.registerplay.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-wa/directorLogo.png"
		},
        {
			"url": "https://nd.registerplay.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-nd/directorLogo.jpeg"
		},
		{
			"url": "https://tieronefastpitch.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/tieronefp/directorLogo.png"
		},
		{
			"url": "https://pasoftball.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-pa/directorLogo.png"
		},
		{
			"url": "https://dcfastpitch.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/dcfp-sc/directorLogo.png"
		},
		{
			"url": "https://usasoftballoftennessee.org",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-tn/directorLogo.png"
		},
		{
			"url": "https://tennesseesoftball.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/dcfp-tn/directorLogo.png"
		},
		{
			"url": "https://usasoftballofwv.com",
			"img": "https://file-storage.sfo3.cdn.digitaloceanspaces.com/email-logo/usasb-wv.png"
		},
		{
			"url": "https://youth1stalliance.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/youth1st/directorLogo.png"
		},
		{
			"url": "https://ssath.registerplay.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/ssath/directorLogo.png"
		},
		{
			"url": "https://tntmaryland.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/tntmaryland/directorLogo.png"
		},
		{
			"url": "https://southernstarsoftball.org",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/southernstar/directorLogo.png"
		},
		{
			"url": "https://grandslam.registerplay.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/grandslam/directorLogo.png"
		},
		{
			"url": "https://aylsportsplay.org",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/ayl/directorLogo.png"
		},
        {
			"url": "https://bagsl.registerplay.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/bagsb/directorLogo.png"
		},
        {
			"url": "https://faribaultbaseball.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/faribaultbb/directorLogo.jpeg"
		},
		{
			"url": "https://amery.registerplay.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/ameryBooster/directorLogo.png"
		},
		{
			"url": "https://owatonnabb.registerplay.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/owatonnabb/directorLogo.png"
		},
		{
			"url": "https://crowriverathletics.com",
			"img": "https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/crowriver/directorLogo.png"
		}
	]

    constructor(
        public router: Router
    ) { }

    ngOnInit(): void {}

}
