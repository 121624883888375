<section class="page-title-area">
    <div class="container wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
        <div class="page-title-content">
    		<img src="assets/img/icons/individual-player.svg" alt="image">
            <h1 class="pt-3">Individual Events Management:<br>Camps, Clinics, Tryouts, and More</h1>
			<p>REGISTERPLAY is the ultimate solution for Event Directors looking to streamline the management of individual sports events such as camps, clinics, tryouts, and more. Our platform caters to the unique needs of these events, ensuring a hassle-free experience for both organizers and participants.</p>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="services-area bg-f9f9f9 pb-70">
    <div class="container">
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Versatile Registration Options</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">With <app-RP/>, creating schedules for your leagues is a seamless process. Our advanced scheduling features enable you to set up entire league schedule with several clicks. You can easily manage game dates, locations, and assign referees or officials, ensuring a smooth flow of matches throughout the league season.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Efficient Player Data Management</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Easily collect and manage player information, including position, jersey size, graduation year, and more. Our platform enables you to export player data effortlessly, simplifying tasks like jersey printing. Plus, with the option to collect friend requests, you can add a personal touch to your event.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Automatic Team Formation and Scheduling</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">For tournaments, <app-RP/> takes the complexity out of team formation. Our system automatically distributes players into teams based on their positions, ensuring balanced and competitive gameplay. These teams are then readily integrated into our familiar scheduling system.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Innovative Clinic Management</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">For clinics, we offer specialized features like measurable reporting. We’re also developing a data collection app to further enhance this functionality, making it easier than ever to track and display player progress.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Seamless Integration and Automation</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Everything you need is in one platform. If a player already exists in a roster, there's no need to create a new record. Event results are automatically updated on the player’s profile, providing a comprehensive view of their participation and achievements.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Customization and Branding</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Tailor your event's identity within the <app-RP/> platform. Customize the look and feel of your event page, add logo, event information, images and content that represent your organization or sponsors. Create a unique and immersive experience for participants and fans, enhancing the overall atmosphere.</p>
			</div>
		</div>

		<section class="feedback-area ptb-100 mt-5 wow animate__animated animate__fadeInLeftBig" data-wow-delay="00ms" data-wow-duration="1000ms">
			<app-feedback page="indv-event"></app-feedback>
		</section>
    </div>
</section>
