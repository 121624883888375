import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured-services',
  templateUrl: './featured-services.component.html',
  styleUrls: ['./featured-services.component.scss']
})
export class FeaturedServicesComponent implements OnInit {

	features = [
		{ 
			"title": 'Registration', 
			"text": 'We cater to all aspects of sports involvement, supporting player, coach, team, club, league, and official registrations. Whatever your role in the sports community, Register Play has you covered with a comprehensive registration solution tailored to your specific needs.'
		}, {
			"title": "Staff Members",
			"text": "Each team, club, association can set up staff members each with custom permissions. These staff members are invited by the owner of the team, club, or members of an association."
		}, {
			"title": "Event Entry",
			"text": "Quickly enter events using various payment methods including Credit Cards, Venmo, PayPal, and more."
		}, {
			"title": "Tournament Schedule",
			"text": "We have everything you need, whether it's a Pool, Bracket Elimination, or combined format. It takes only two clicks to advance teams from pool play to brackets."
		}, {
			"title": "League Schedule",
			"text": "Manage your leagues with field assignment and score entry tools. Use flexible options that can allow teams to assign their own fields and enter their own results."
		}, {
			"title": "Facilities",
			"text": "Our schedule module takes into consideration prioritizing games after sunset on lighted fields. Our club feature allows clubs to schedule fields for specific league types."
		}, {
			"title": "Reports",
			"text": "You can track your organization's progress and production with advanced reports like financial transactions and registration reports."
		}, {
			"title": "Rankings",
			"text": "Review strength of schedule or our Cross Class Ranking (CCR) system that rates teams against all other teams from other classes."
		}, {
			"title": "Communication",
			"text": "You can send emails to all parents/players, coaches, or officials under one of your events or a specific division of your event."
		}, {
			"title": "Compliance",
			"text": "Use our compliance tools to track coaching certifications, background checks, SafeSport, or concussion. Administrators can quickly verify birth certificates for player birthdates."
		}, {
			"title": "Content Management",
			"text": "The CMS Management feature of Register Play puts you in control of your platform's content, enabling easy creation, customization, and organization of your website's pages."
		}, {
			"title": "Mobile App",
			"text": "Experience the convenience of managing tournaments, teams, and events right from your smartphone or tablet. Stay informed with real-time updates and important announcements through push notifications."
		}
	]

  constructor() { }

  ngOnInit(): void {
  }

}
