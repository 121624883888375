import { Component, OnInit } from '@angular/core';
import blogPosts from './content.json';

@Component({
	selector: 'app-blog-list',
	templateUrl: './blog-list.component.html',
	styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements OnInit {

	// load list from json file
	list: any[] = blogPosts;

	constructor() { }

	ngOnInit(): void {
	}

}
