<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Stay in the Game</h1>
			<h3 style="color: white">Latest Features and Updates from Register Play</h3>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="blog-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let item of list">
                <div class="single-blog-post" style="height: 100%">
                    <div class="image">
                        <a routerLink="/blog/{{item.slug}}" class="d-block">
                            <img [src]="item.image" alt="image">
                        </a>
                    </div>

                    <div class="content">
						<div class="info">
							<span>{{item.date | date: "MMM d, yyyy"}}</span>
						</div>
                        <h3><a routerLink="/blog/{{item.slug}}">{{item.title}}</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>