<div class="container">
    <div class="section-title">
        <!-- <span class="sub-title">Features</span> -->
        <h2 class="wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">Let’s Check Our Features</h2>
        <p class="wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms">Experience the power of our platform with a wide range of features designed to seamlessly support your organization, team, or tournament. From streamlined registration and efficient team management to advanced scheduling and comprehensive tournament tools, we have everything you need to run your operations with ease and success.</p>
    </div>

    <div class="row  d-flex flex-grow-1">
        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let feature of features">
            <div class="single-featured-services-box wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms">
                <h3>{{feature.title}}</h3>
                <p>{{feature.text}}</p>
            </div>
        </div>

    </div>
</div>