// a component to show Register Play text in color
// Path: src/app/components/common/rp-label/rp-label.component.ts

import { Component } from '@angular/core';

@Component({
    selector: 'app-RP',
    template: `<span class="rp0">Register</span>&nbsp;<span class="rp1">Play</span>`,
    styles: [`.rp0, .rp1 {font-weight: bold; text-transform: uppercase; letter-spacing: 0.1em;}`
    ,`.rp0 {color: #223f86;}`
    ,`.rp1 {color: #b92025;}`
    ]
  })
  export class RPLabelComponent {

    constructor() { }
}
