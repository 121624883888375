<section class="page-title-area">
    <div class="container">
        <div class="page-title-content wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
    		<img src="assets/img/icons/teams.svg" alt="image">
            <h1 class="pt-3">Teams:<br>Unite, Compete, and Excel Together</h1>
			<p>REGISTER PLAY provides a dedicated teams page that fosters a sense of unity, competition, and excellence among participating teams. Whether you're managing a sports league, tournament, or event, our platform offers a range of features designed to enhance the team experience and elevate their performance.</p>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="services-area bg-f9f9f9 pb-70">
	<div class="container position-relative z-index-3 my-5">
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation animated fadeInRightShorter appear-animation-visible" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Team Profiles</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Elevate your team's presence with a dedicated team page on <app-RP/>. Showcase team information, logos, and captivating pictures to establish a strong identity. Highlight achievements, display team rosters, and provide updates on upcoming matches, empowering your team to make a lasting impact in the sporting community.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation animated fadeInRightShorter appear-animation-visible" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Roster Management</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Simplify team roster management with <app-RP/>. Parents and players can easily join teams and manage their own profiles, while our platform streamlines the process of ensuring player eligibility and compliance with rules and regulations. Additionally, you have the convenience of printing rosters for events, making organization a breeze.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation animated fadeInRightShorter appear-animation-visible" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Schedule and Results</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Access team-specific schedules, and game results with ease. <app-RP/> provides a centralized platform for teams to view upcoming matches, track results, enter scores (if allowed) and analyze performance. Real-time updates keep teams informed about any schedule changes or important announcements, ensuring they are always ready for their next challenge.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation animated fadeInRightShorter appear-animation-visible" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Rankings and Leaderboards</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Monitor team standings and track progress throughout the season. <app-RP/> automatically calculates and updates standings and rankings based on match results, providing an accurate representation of team performance. Rankings motivate teams to strive for excellence, creating a healthy and competitive environment.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation animated fadeInRightShorter appear-animation-visible" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Team Communication (Coming Soon)</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Effective communication is essential for team success. <app-RP/> facilitates seamless team communication by providing messaging tools that allow coaches, players, and team managers to stay connected. Coordinating practice sessions, sharing strategy, and fostering team spirit has never been easier.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation animated fadeInRightShorter appear-animation-visible" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mt-5 mb-2 text-justified">Join the <app-RP/> community and experience the power of our teams page. Empower your teams to unite, compete, and excel together. Enhance team identity, streamline communication, and provide a platform for teams to showcase their talent and achievements. Get started today and revolutionize the team experience with <app-RP/>.</p>
			</div>
		</div>
    </div>
</section>
