<section class="page-title-area">
    <div class="container wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
        <div class="page-title-content">
    		<img src="assets/img/icons/league.svg" alt="image">
            <h1 class="pt-3">Comprehensive Sport Solutions for All.</h1>
			<p>From Softball to Basketball, Football to Hockey, we have you covered. Streamline your sports management, register players, schedule matches, and elevate your events with ease. Explore our versatile platform designed to support all sports and create unforgettable experiences for players, coaches, and fans alike. Join us and take your sporting endeavors to new heights.</p>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="featured-services-area pt-100 pb-70">
	<div class="container">
		<div class="row wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let sport of sports">
				<div class="single-featured-services-box link" routerLink="/sport/{{sport.code}}">
					<div class="icon">
						<!-- <i class="flaticon-research"></i> -->
						<img [src]="'/assets/img/icons/' + sport.code + '.svg'" alt="image">
						<div class="circles-box">
							<div class="circle-one"></div>
							<div class="circle-two"></div>
						</div>
					</div>
					<h3>{{sport.name}}</h3>
				</div>
			</div>
		</div>

		<div class="row wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 col-md-12">
				<div class="solutions-inner-content">
					<h4>Can't find your sport on the list?</h4>
					<p>No worries! We continuously add new sports to our platform. Contact us, and we'll fast-track the process to accommodate your sport.</p>
					<p><a routerLink="/contact" class="default-btn-with-radius">Contact us <i class="flaticon-next-button"></i></a></p>
				</div>
			</div>
		</div>
	</div>
</section>