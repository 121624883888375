<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>{{post.title}}</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="blog-details-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img [src]="post.image" [alt]="post.title">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li>
                                    <i class='bx bx-calendar'></i>
                                    <span>Last Updated</span>
									{{post.date | date: "MMM d, yyyy"}}
                                </li>
								<li>
									<i class='bx bx-folder-open'></i>
									<span>Category</span>
									{{post.category}}
								</li>
                            </ul>
                        </div>
						<h2 style="margin-top: 24px">{{post.title}}</h2>
                        <div [innerHTML]="post.content"></div>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-purchase-tag'></i></span>
                            {{post.category}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>