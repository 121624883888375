import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-sportsHome',
	templateUrl: './sportsHome.component.html',
	styleUrls: ['./sportsHome.component.scss']
})
export class SportsHomeComponent implements OnInit {

	sports = [
		{ "name": "Softball", "code": "softball" },
		{ "name": "Baseball", "code": "baseball" },
		{ "name": "Basketball", "code": "basketball" },
		{ "name": "Football", "code": "football" },
		{ "name": "Curling", "code": "curling" },
		{ "name": "Hockey", "code": "hockey" }
	];
	constructor() { }

	ngOnInit(): void {
	}

}
