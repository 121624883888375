import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    constructor(
        public router: Router
    ) { }

    ngOnInit(): void {
    }

    // copyrightText: Text[] = [
    //     {
    //         text: 'Plor is Proudly Powered by',
    //         linkText: 'EnvyTheme',
    //         link: 'https://envytheme.com/'
    //     }
    // ]

}
// class Text {
//     text : string;
//     linkText : string;
//     link : string;
// }