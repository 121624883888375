<div class="new-main-banner-wrap-area">
	<div class="container-fluid">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="new-main-banner-wrap-content">
					<span class="sub-title wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">MANAGEMENT PLATFORM</span>
					<h1 class="wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms"><span>{{selectedSport.name}}</span></h1>
					<p class="wow animate__animated animate__fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">Discover the Power of {{selectedSport.name}} Management with Register Play. Our dedicated platform offers a tailored suite of features to streamline {{selectedSport.name}} tournaments, leagues, and events. From effortless player registrations to advanced scheduling and real-time updates, our platform empowers you to excel in managing {{selectedSport.name}} activities. Join us now and take your {{selectedSport.name}} organization to the next level.</p>
					<div class="btn-box wow animate__animated animate__fadeInLeft" data-wow-delay="300ms" data-wow-duration="1000ms">
						<a routerLink="/contact" class="default-btn-with-radius btn-color-{{selectedSport.color}}">Get Started <i class="flaticon-next-button"></i></a>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-md-12">
				<div class="new-main-banner-wrap-image wow animate__animated animate__fadeInRight" data-wow-delay="300ms" data-wow-duration="1000ms">
					<img src="assets/img/sports/{{selectedSport.code}}.webp" alt="image">
					<div class="wrap-circle-shape">
						<img src="assets/img/more-home/banner/circle-shape-{{selectedSport.color ?? 'red'}}.png" alt="image">
					</div>
				</div>
			</div>
		</div>
	</div>
	<svg style="display:none;">
		<defs>
			<filter id="stroke-text">
				<feMorphology radius="1" operator="dilate"></feMorphology>
				<feComposite operator="xor" in="SourceGraphic" />
			</filter>
		</defs>
	</svg>
	<div class="new-main-banner-wrap-shape">
		<img src="assets/img/icons/ball-{{selectedSport.code}}.svg" alt="image">
	</div>
</div>

<section class="featured-services-area pt-100 pb-70">
	<app-featured-services></app-featured-services>
</section>