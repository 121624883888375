<section class="page-title-area">
    <div class="container">
        <div class="page-title-content wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
    		<img src="assets/img/icons/players.svg" alt="image">
            <h1 class="pt-3">Players:<br>Celebrating Individual Skills</h1>
			<p>REGISTER PLAY provides a dedicated players page that celebrates the talents and contributions of individual athletes while fostering a sense of camaraderie within the team. Our platform offers a range of features designed to enhance the player experience and facilitate their growth and development.</p>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="services-area bg-f9f9f9 pb-70">
	<div class="container">
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation animated fadeInRightShorter appear-animation-visible" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Player Profiles</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Create personalized player profiles within Register Play to showcase individual accomplishments, skills, and biographical information. Each player can have a dedicated page that highlights their achievements, statistics, roster and individual event history, allowing them to showcase their abilities and build a strong personal presence within the sporting community.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation animated fadeInRightShorter appear-animation-visible" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500" style="animation-delay: 500ms;">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Performance Tracking</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">Register Play revolutionizes player profiles by showcasing individual event results, making it easy to track and highlight skill improvements over time. Our platform enables scouts and college coaches to effortlessly follow and monitor players they are interested in, providing a valuable connection between talented athletes and opportunities for further development.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<h4 class="text-color-primary font-weight-medium positive-ls-3 text-4 mt-5 mb-0 text-uppercase">Team Schedule</h4>
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mb-2 text-justified">View team schedules, practices and upcoming games, providing players with a clear understanding of their team's schedule and commitments. Register Play ensures players are up to date with event details, allowing them to prepare and perform at their best during each game.</p>
			</div>
		</div>
		<div class="row align-items-center justify-content-center wow animate__animated animate__fadeInDown" data-wow-delay="00ms" data-wow-duration="1000ms">
			<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="500">
				<p class="custom-font-secondary custom-font-size-1 line-height-7 pb-2 mt-5 mb-2 text-justified">Join the Register Play community and unlock the full potential of your players. Celebrate individual skills, nurture team spirit, and provide a platform for players to showcase their talent and accomplishments. Experience the power of our players page and elevate the player experience with Register Play. Get started today and revolutionize the way players engage with their teams and the sporting community.</p>
			</div>
		</div>
	</div>
</section>