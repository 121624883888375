import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import blogPosts from '../blog-list/content.json';

@Component({
	selector: 'app-blog-details',
	templateUrl: './blog-details.component.html',
	styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

	constructor(private route: ActivatedRoute) {
	}

	blogPosts: any[] = blogPosts;

	post: any;
	ngOnInit(): void {
		// subscribe to the navigation end event
		this.route.params.subscribe(params => {
			// get the code from the url
			let slug = params['slug'];
			// find the sport in the array of sports
			this.post = this.blogPosts.find(post => post.slug === slug);
		});
	}

}
