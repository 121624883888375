<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
			<img src="assets/img/icons/compare.svg" alt="image">
            <h1>How do we compare?</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="comparison-area bg-f9f9f9 ptb-100" style="padding-right: 40px; padding-left: 40px">
	<div class="table-responsive">
		<table class="table table-bordered table-striped table-hover caption-top">
			<caption>for Your Coaches / Players</caption>
			<thead class="">
				<tr>
					<th scope="col">Topics</th>
					<th class="text-center" scope="col">Register Play</th>
					<th class="text-center" scope="col">Sports Engine</th>
					<th class="text-center" scope="col">Tourney Machine</th>
				</tr>
			</thead>
			<tbody class="table-group-divider">
				<tr>
					<th scope="row">User-friendly experience for parents and players, with easy access to schedules, favoriting teams, players, and facilities.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Retention of event results in the system, allowing historical access to past events for reference and analysis.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Seamless customer experience with the same branded site for all aspects, including schedules and information.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Guest Player Network for easy guest player management based on event needs.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Coaches save time as team information is retained for multiple event entries.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Comprehensive Game Matchup feature offering detailed insights about teams for individual games.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Public player pages with controlled content, allowing parents to manage their child's information.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Easy-to-use roster management tools, reducing administrative burden for coaches.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Player history visible and tracked throughout their sports journey.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Parent/guardian and split family management of child profiles for flexibility and control.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<!-- <tr>
					<th scope="row">xxx</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr> -->
			</tbody>
		</table>
		<table class="table table-bordered table-striped table-hover caption-top">
			<caption>for Event Directors</caption>
			<thead class="">
				<tr>
					<th scope="col">Topics</th>
					<th class="text-center" scope="col">Register Play</th>
					<th class="text-center" scope="col">Sports Engine</th>
					<th class="text-center" scope="col">Tourney Machine</th>
				</tr>
			</thead>
			<tbody class="table-group-divider">
				<tr>
					<th scope="row">Multiple directors and staff can access the same event, manage lodging, score entry, and other actions effortlessly.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Built-in safeguards to prevent teams from higher classes from entering lower-class events, maintaining fair competition.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Powerful seeding options based on points and rankings, encouraging teams to participate in more events within your program.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Advanced waiting list features that increase team participation by filling vacant spots automatically.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Event entry coupon system - Seamlessly generate customized discounts and distribute them in a variety of uses.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Easy management of individual events, including camps, clinics, and tryouts, with team formation and game schedule options.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Recalculate schedule feature considers field availability and constraints, facilitating quick rescheduling when needed.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Schedule Conflict Checker to ensure error-free schedules for smooth event execution.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Umpire tools for game assignments and individual schedule reviews, simplifying umpire management.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Options for coaches to enter scores, providing flexibility and convenience in score reporting.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Coach and team compliance tracking, ensuring adherence to regulations and guidelines.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">League communication features for future matchup discussions and coordination.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Multiple options for coaches to assign fields in certain league types, simplifying field allocation.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Support tools to assist and impersonate customers for prompt issue resolution.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Capability to track teams qualifying for states and nationals, with access permissions for specific members or qualified teams.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
			</tbody>
		</table>
		<table class="table table-bordered table-striped table-hover caption-top">
			<caption>for State Commissioners</caption>
			<thead class="">
				<tr>
					<th scope="col">Topics</th>
					<th class="text-center" scope="col">Register Play</th>
					<th class="text-center" scope="col">Sports Engine</th>
					<th class="text-center" scope="col">Tourney Machine</th>
				</tr>
			</thead>
			<tbody class="table-group-divider">
				<tr>
					<th scope="row">Minimal, high-quality, screened advertisements without any visitor tracking</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Easy control over web pages, menus, colors, logo, banners, and more.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Efficient support system and feedback/help ticket management.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Direct visitors to your branded website and mobile app instead of redirecting them a generic website.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">You can create custom web pages for your yearly event schedule according to the sport.</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Ability to invite staff members to help you manage your organization</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Ability to invite your sub-directors to run events</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
				<tr>
					<th scope="row">Ability to let your sub-directors collect their own event money</th>
					<td class="text-center"><i class='bx bx-lg bx-check green-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
					<td class="text-center"><i class='bx bx-lg bx-x red-fg'></i></td>
				</tr>
			</tbody>
		</table>
	</div>
</section>