import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomepageOneComponent } from './components/pages/homepage-one/homepage-one.component';
import { HomeoneServicesComponent } from './components/pages/homepage-one/homeone-services/homeone-services.component';
import { HomeoneAboutComponent } from './components/pages/homepage-one/homeone-about/homeone-about.component';
import { HomeoneMainBannerComponent } from './components/pages/homepage-one/homeone-main-banner/homeone-main-banner.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FeaturedServicesComponent } from './components/common/featured-services/featured-services.component';
import { PricingPageComponent } from './components/pages/pricing-page/pricing-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { GetStartedComponent } from './components/common/get-started/get-started.component';
import { LeagueSolutionsComponent } from './components/pages/solutions/leagues/leagues.component';
import { StateOfficesComponent } from './components/pages/solutions/stateOffices/stateOffices.component';
import { LocalAssociationsComponent } from './components/pages/solutions/localAssociations/localAssociations.component';
import { TeamsComponent } from './components/pages/solutions/teams/teams.component';
import { PlayersComponent } from './components/pages/solutions/players/players.component';
import { SportsHomeComponent } from './components/pages/sports/home/sportsHome.component';
import { SportDetailComponent } from './components/pages/sports/detail/sportDetail.component';
import { ComparisonPageComponent } from './components/pages/comparison/comparison.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PhoneMaskDirective } from './components/shared/directives/phone-mask.directive';
import { HometwoMainBannerComponent } from './components/pages/homepage-two/hometwo-main-banner/hometwo-main-banner.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { WhatWeDoComponent } from './components/common/what-we-do/what-we-do.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { ProjectStartComponent } from './components/common/project-start/project-start.component';
import { BlogListComponent } from './components/pages/blog-list/blog-list.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { ThankYouComponent } from './components/pages/thank-you/thank-you.component';
import { IndvEventSolutionsComponent } from './components/pages/solutions/indv-events/indv-events.component';
import { RPLabelComponent } from './components/common/rp-label/rp-label.component';
import { TeamPlusComponent } from './components/pages/solutions/teams/teamsPlus/TeamsPlus.component';

@NgModule({
	declarations: [
		AppComponent,
		FooterComponent,
		HomepageOneComponent,
		HomeoneServicesComponent,
		HomeoneAboutComponent,
		HomeoneMainBannerComponent,
		NavbarComponent,
		FeaturedServicesComponent,
		PricingPageComponent,
		ErrorPageComponent,
		ContactPageComponent,
		GetStartedComponent,

		HometwoMainBannerComponent,
		FunfactsComponent,
		WhatWeDoComponent,
		PartnerComponent,
		FeedbackComponent,
		ProjectStartComponent,

		LeagueSolutionsComponent,
		IndvEventSolutionsComponent,
		StateOfficesComponent,
		LocalAssociationsComponent,
		TeamsComponent,
        TeamPlusComponent,
		PlayersComponent,

		SportsHomeComponent,
		SportDetailComponent,
		ComparisonPageComponent,

		BlogListComponent,
		BlogDetailsComponent,

		PhoneMaskDirective,
        ThankYouComponent,

        RPLabelComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
        BrowserAnimationsModule,
        MatDialogModule
	],
	exports: [PhoneMaskDirective],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
