<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="project-start-image">
                <img src="assets/img/project-start1.png" alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="project-start-content">
                <span class="sub-title">Get Started</span>
                <h2>We Like to Start Your Project With Us</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                <a routerLink="/contact" class="default-btn">Get Started</a>
            </div>
        </div>
    </div>
</div>