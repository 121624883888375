<section class="main-banner-area">
    <div class="container-fluid">
        <iframe class="promoVideo"
            src="https://customer-pdc4yijcmuyr5pl1.cloudflarestream.com/8aa318acd4f92a6494e3a3ae68fef8e2/iframe?poster=https%3A%2F%2Fcustomer-pdc4yijcmuyr5pl1.cloudflarestream.com%2F8aa318acd4f92a6494e3a3ae68fef8e2%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D37.25s%26height%3D600&title=Register+Play+Promo&channel-link=https%3A%2F%2Fregisterplay.com"
            style="border: none"
            allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
            allowfullscreen="true"
    ></iframe>
    </div>
</section>
