<section class="home-wrapper-area">
    <div class="container-fluid">
        <div class="home-slides owl-carousel owl-theme">
            <div class="single-banner-item">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <span class="sub-title">Why Do People Choose <u>Register Play</u>?</span>
                            <h1>Single Platform Convenience</h1>
                            <p>We offer an all-in-one solution that encompasses tournament registration, scheduling, and management, all within one convenient platform. </p>
                            <p>This integrated approach eliminates the need for multiple systems, streamlining processes, and saving valuable time for organizers.</p>
                            <div class="btn-box">
                                <div class="d-flex align-items-center">
                                    <a routerLink="/contact" class="default-btn">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/slider/softball.webp" alt="softball">
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-banner-item">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <span class="sub-title">Why Do People Choose <u>Register Play</u>?</span>
                            <h1>User-Friendly Interface</h1>
                            <p>Our platform is designed with a user-friendly interface that is both intuitive and responsive, ensuring that coaches, players, and tournament directors can easily navigate and utilize the system.</p>
                            <p>This streamlined approach reduces the learning curve and allows users to quickly get to the task at hand, whether it's setting up a new league, registering for an event, or checking the latest standings and results.</p>
                            <div class="btn-box">
                                <div class="d-flex align-items-center">
                                    <a routerLink="/contact" class="default-btn">Request Demo</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/slider/basketball.webp" alt="basketball">
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-banner-item">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <span class="sub-title">Why Do People Choose <u>Register Play</u>?</span>
                            <h1>Reliable Customer Support</h1>
                            <p>Our exceptional customer support team is committed to providing prompt and helpful assistance, ensuring that every query and concern is addressed with the utmost attention.</p>
                            <p>We recognize that sports events often take place on weekends, and that's when you need support the most. That's why our dedicated professionals are available round-the-clock, especially during weekends, to provide the assistance you need exactly when it's most critical.</p>
                            <div class="btn-box">
                                <div class="d-flex align-items-center">
                                    <a routerLink="/contact" class="default-btn">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
							<img src="assets/img/slider/flag-football.webp" alt="flag-football">
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-banner-item">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <span class="sub-title">Why Do People Choose <u>Register Play</u>?</span>
                            <h1>Unwavering Reliability & Performance</h1>
                            <p>Your event website is equipped to welcome any number of visitors without a hitch, thanks to our team that's always on standby to provide extra support during busy times.</p>
                            <p>Our fail-safe system is designed to quickly fix any issues, much like a relay race where the next runner takes over without missing a step, ensuring your site stays up and running smoothly, allowing you to concentrate on hosting a great event.</p>
                            <div class="btn-box">
                                <div class="d-flex align-items-center">
                                    <a routerLink="/contact" class="default-btn">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
							<img src="assets/img/slider/basketball2.webp" alt="basketball2">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape14.png" alt="image"></div>
</section>
