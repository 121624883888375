<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="what-we-do-content wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
                <span class="sub-title">What We Do</span>
                <h2>We Help you to Grow With Next Level Solutions</h2>
                <p>At Register Play, we are dedicated to revolutionizing tournament and sports management. Our comprehensive platform offers a seamless and efficient solution for organizers, coaches, players, and participants alike. With a user-friendly interface and cutting-edge technology, we simplify every aspect of tournament organization, from registration and scheduling to communication and result tracking.</p>
				<p>As the all-in-one hub for sports events, Register Play empowers you to create, manage, and elevate your tournaments to new heights. Whether you're organizing local community leagues or large-scale regional events, our platform adapts to your needs, providing customizable features to match your unique requirements. With secure data management and reliable customer support, we ensure a smooth and enjoyable experience for everyone involved. Join Register Play today and experience the future of sports management in the palm of your hands.</p>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="what-we-do-content-accordion wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms">
                <div class="accordion" id="whatWeDoAccordion">
                    <ng-container *ngFor="let topic of topics;let i = index">
						<div class="accordion-item">
							<div class="accordion-header">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" aria-expanded="false" [attr.data-bs-target]="'#collapse' + i" [attr.aria-controls]="'collapse'+i">
									{{topic.title}} <i class="flaticon-minus"></i>
								</button>
							</div>
							<div id="collapse{{i}}" class="accordion-collapse collapse" data-bs-parent="#whatWeDoAccordion">
								<div class="accordion-body">
									<p>{{topic.text}}</p>
								</div>
							</div>
						</div>
					</ng-container>
                    <!-- <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse99" aria-expanded="false" aria-controls="collapse99">
                                Content Generation <i class="flaticon-minus"></i>
                            </button>
                        </div>
                        <div id="collapse99" class="accordion-collapse collapse" data-bs-parent="#whatWeDoAccordion">
                            <div class="accordion-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Pay Per Click Services <i class="flaticon-minus"></i>
                            </button>
                        </div>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#whatWeDoAccordion">
                            <div class="accordion-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>