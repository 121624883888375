<div class="navbar-area">
    <div class="plor-responsive-nav">
        <div class="container-fluid">
            <div class="plor-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/logo.png" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="plor-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="logo">
                </a>
                <div class="collapse navbar-collapse mean-menu">
					<ul class="navbar-nav">
						<!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Home <i class='bx bx-chevron-down'></i></a>
							<ul class="dropdown-menu">
								<li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo - 1</a></li>
								<li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo - 2</a></li>
								<li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo - 3</a></li>
								<li class="nav-item"><a routerLink="/home-four" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo - 4</a></li>
								<li class="nav-item"><a routerLink="/home-five" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo - 5</a></li>
								<li class="nav-item"><a routerLink="/home-six" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo - 6</a></li>
								<li class="nav-item"><a routerLink="/home-seven" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo - 7</a></li>
								<li class="nav-item"><a routerLink="/home-eight" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo - 8</a></li>
								<li class="nav-item"><a routerLink="/home-nine" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo - 9</a></li>
								<li class="nav-item"><a routerLink="/home-ten" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo - 10</a></li>
								<li class="nav-item"><a routerLink="/home-eleven" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo - 11</a></li>
								<li class="nav-item"><a routerLink="/home-twelve" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo - 12</a></li>
							</ul>
						</li> -->
						<li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
						<li class="nav-item"><a routerLink="/sports" class="nav-link">Sports <i class='bx bx-chevron-down'></i></a>
							<ul class="dropdown-menu">
								<li class="nav-item"><a routerLink="/sport/softball" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Softball</a></li>
								<li class="nav-item"><a routerLink="/sport/baseball" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Baseball</a></li>
								<li class="nav-item"><a routerLink="/sport/basketball" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Basketball</a></li>
								<li class="nav-item"><a routerLink="/sport/football" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Football</a></li>
								<li class="nav-item"><a routerLink="/sport/curling" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Curling</a></li>
								<li class="nav-item"><a routerLink="/sport/hockey" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Hockey</a></li>
							</ul>
						</li>
						<li class="nav-item"><a href="javascript:void(0)" class="nav-link">Solutions <i class='bx bx-chevron-down'></i></a>
							<ul class="dropdown-menu">
								<li class="nav-item"><a routerLink="/solutions/individual-events" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Individual Events</a></li>
								<li class="nav-item"><a routerLink="/solutions/leagues" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Leagues</a></li>
								<li class="nav-item"><a routerLink="/solutions/state-offices" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">State Offices</a></li>
								<li class="nav-item"><a routerLink="/solutions/sport-organizations" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Sport Organizations</a></li>
								<li class="nav-item"><a [routerLink]="'/solutions/teams'" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Teams</a>
                                <li class="nav-item"><a routerLink="/solutions/teamPlus" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"> -- Team+</a></li>
								<li class="nav-item"><a routerLink="/solutions/players" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Players</a></li>
							</ul>
						</li>
						<li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog</a></li>
						<li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
						<li class="nav-item"><a href="https://calendly.com/registerplay/contact-us" (click)="track()" class="nav-link" target="_blank">Request Demo</a>
                            <img *ngIf="tracked" src="https://ct.capterra.com/capterra_tracker.gif?vid=2290412&vkey=29627c2b6a4b885cfa7c52bfae485705">
                        </li>
					</ul>


                    <!-- <div class="others-option d-flex align-items-center">
						<div class="extra-nav">
							<div class="extra-cell">
								<ul class="list-unstyled social-bx d-flex flex-wrap align-content-center">
									<li><a href="https://www.facebook.com/RegisterPlaySoftware" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
									<li><a href="https://twitter.com/RegisterPlayInc" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
										<li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
										<li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
										<li><a href="tel:7755777529" class="d-block" target="_blank"><i class='bx bx-phone'></i></a></li>
										<li style="color:#fff">|</li>
										<li><a href="tel:7755777529" class="d-block" target="_blank"><i class='bx bxs-calendar'></i> Request Demo</a></li>
								</ul>
							</div>
						</div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
    <div class="others-option-for-responsive">
        <div class="container">

            <div class="container">
                <div class="option-inner">
                    <div class="others-option justify-content-center d-flex align-items-center">
                        <div class="option-item">
                            <div class="cart-btn">
                                <a routerLink="/cart">
                                    <i class="flaticon-shopping-cart"></i>
                                    <span>1</span>
                                </a>
                            </div>
                        </div>
                        <div class="option-item">
                            <div class="search-box">
                                <i class="flaticon-search"></i>
                            </div>
                        </div>
                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="flaticon-menu" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
